<template>
  <div class="EduNotice">
    <el-tooltip content="消息" placement="bottom">
      <img
        class="EduMenuImg"
        style="cursor: pointer"
        @click="gotoNotice"
        src="@/assets/EduNotice.png"
        alt="EduNotice"
      />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "EduNotice",
  data() {
    return {
      activeName: "notice",
    };
  },
  methods: {
    gotoNotice() {
      if (this.$route.path === "/home") {
        this.$emit("activeName", this.activeName);
      } else {
        this.$router.push({
          path: "/home",
          query: {
            activeName: "notice",
          },
        });
      }
    },
  },
};
</script>
