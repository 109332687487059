<template>
  <header class="EduHeader">
    <EduLogo @setGoToUrl="setGoToUrl" />
    <EduMenu
      @setGoToUrl="setGoToUrl"
      :memberAvatar="memberAvatar"
      @activeName="activeName"
    />
  </header>
</template>

<script>
import EduLogo from "./components/EduLogo";
import EduMenu from "./components/EduMenu.vue";
export default {
  name: "EduHeader",
  props: {
    memberAvatar: {
      type: String,
      default: "",
    },
  },
  components: {
    EduLogo,
    EduMenu,
  },
  methods: {
    setGoToUrl(_url) {
      this.$emit("setGoToUrl", _url);
    },
    activeName(name) {
      console.log("name", name);
      this.$emit("activeName", name);
    },
  },
};
</script>

<style lang="scss" scoped>
.EduHeader {
  background-color: $color-primary;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  left: 0;
}
.HeaderWidth {
  width: 1200px;
  margin: 0 auto;
}
</style>
