<template>
  <router-link to="" @click.native="goTo('/home')" class="EduLogo">
    <img src="~@/assets/logo.png" alt="logo" />
    <h1><span>华路文化</span> <strong>CPA</strong> <span>online</span></h1>
  </router-link>
</template>

<script>
export default {
  name: "EduLogo",
  methods: {
    goTo(_url) {
      if (this.$route.path.indexOf("/NewTopic") != -1) {
        this.$emit("setGoToUrl", _url);
      } else {
        this.$router.push({
          path: _url,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.EduLogo {
  display: flex;
  align-items: center;

  h1 {
    font-size: 18px;
    color: $color-white;
    font-weight: normal;
    margin-left: 5px;
  }

  img {
    width: 42px;
  }
}
</style>
