<template>
  <div class="EduAvatar">
    <el-tooltip content="个人中心" placement="bottom">
      <!-- <router-link class="link-type" to="MyAccount">
        <img class="EduMenuImg" src="@/assets/EduAvatar.png" alt="EduAvatar" />
      </router-link> -->
      <router-link class="link-type" to="" @click.native="goTo('/MyMessage')">
        <img
          v-if="avatar"
          width="37"
          height="37"
          :src="avatar"
          alt="EduAvatar"
        />
        <img
          v-else
          class="EduMenuImg"
          src="@/assets/EduAvatar.png"
          alt="EduAvatar"
        />
      </router-link>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "EduAvatar",
  watch: {
    memberAvatar: {
      // eslint-disable-next-line no-unused-vars
      handler(val, oldval) {
        if (val != "") {
          this.avatar = this.memberAvatar;
        }
      },
      deep: true,
    },
  },
  props: {
    memberAvatar: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      avatar: "",
    };
  },
  created() {
    this.avatar = sessionStorage.getItem("MEMBER_AVATAR");
    if (this.avatar == "undefined") {
      this.avatar = "";
    }
  },
  methods: {
    goTo(_url) {
      if (this.$route.path.indexOf("/NewTopic") != -1) {
        this.$emit("setGoToUrl", _url);
      } else {
        this.$router.push({
          path: _url,
        });
      }
    },
  },
};
</script>
