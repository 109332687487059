<template>
  <div class="EduLogout">
    <el-tooltip content="退出" placement="bottom">
      <img
        class="EduMenuImg"
        :src="imgColor ?  image2 : image"
        style="cursor: pointer"
        @click="goTo"
      />
    </el-tooltip>
  </div>
</template>

<script>
//import { removeookiesByKey } from "@/utils/auth.js";
import store from "@/store";
import { logout } from "@/api/authController";
export default {
  name: "EduLogout",
  props: {
    imgColor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      image: require('@/assets/login/logout.png'),
      image2: require('@/assets/login/logout2.png')
    }
  },
  methods: {
    userLogout() {
      store.state.isLogout = true;
      this.$store.commit("del_token");
      this.$router.push({ path: "/Login" });
    },
    async goTo() {
      if (this.$route.path.indexOf("/NewTopic") != -1) {
        this.$emit("setGoToUrl", "LOGOUT");
      } else {
        const { code } = await logout();
        if (code == 20000) {
          this.userLogout();
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.EduMenuImg {
  width: 32px;
  height: 32px;
  margin-top: 4px;
}

</style>