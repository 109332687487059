<template>
  <ul class="EduMenu">
    <!--<li>
      <EduShopingCar />
    </li>-->
    <!-- <li>
      <EduAlumniBook />
    </li> -->
    <li>
      <EduNotice @activeName="activeName"  />
    </li>
    <li>
      <EduAvatar @setGoToUrl="setGoToUrl" :memberAvatar="memberAvatar"/>
    </li>
    <li>
      <EduLogout @setGoToUrl="setGoToUrl" />
    </li>
  </ul>
</template>

<script>
import EduShopingCar from "./EduShopingCar.vue";
import EduAlumniBook from "./EduAlumniBook.vue";
import EduNotice from "./EduNotice.vue";
import EduAvatar from "./EduAvatar.vue";
import EduLogout from "./EduLogout.vue";
export default {
  name: "EduMenu",
  props: {
    memberAvatar: {
      type: String,
      default: "",
    },
  },
  components: {
    EduShopingCar,
    EduAlumniBook,
    EduNotice,
    EduAvatar,
    EduLogout,
  },
  methods: {
    setGoToUrl(_url) {
      this.$emit("setGoToUrl", _url);
    },
    activeName(name){
      this.$emit("activeName",  name);
    }
  },
};
</script>

<style lang="scss" scoped>
.EduMenu {
  display: flex;
  align-items: center;
  li {
    margin-right: 10px;
  }

  li:last-child {
    margin-right: 0;
  }

  ::v-deep {
    .EduMenuImg {
      width: 32px;
    }
  }
}
</style>
