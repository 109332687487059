<template>
  <div class="EduAlumniBook">
    <el-tooltip content="同学录" placement="bottom">
      <router-link to="/AlumniBook">
        <img
          class="EduMenuImg"
          src="@/assets/EduAlumniBook.png"
          alt="EduAlumniBook"
        />
      </router-link>
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "EduAlumniBook",
};
</script>
