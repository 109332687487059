import request from "@/utils/request";

const BASE_PATH = "/front/auth/";
export function loginInfo(data) {
  return request({
    url: BASE_PATH + "login",
    method: "post",
    data,
  });
}

export function logout() {
  return request({
    url: BASE_PATH + "/logout",
    method: "post",
  });
}

export function getImageCaptcha() {
  return BASE_PATH + "captcha-image";
}

export function register(data, query) {
  return request({
    url: BASE_PATH + "register",
    method: "post",
    data,
    params: query,
  });
}

export function sendSmscode(data) {
  return request({
    url: BASE_PATH + "send-smscode",
    method: "post",
    params: data,
  });
}

export function resetPassword(data, query) {
  return request({
    url: BASE_PATH + "reset-password",
    method: "post",
    data,
    params: query,
  });
}

export function changePassword(query, data) {
  return request({
    url: BASE_PATH + "change-password",
    method: "post",
    data,
    params: query,
  });
}

export function checkCellphone(data) {
  return request({
    url: BASE_PATH + "check-cellphone",
    method: "POST",
    params: data,
  });
}

export function checkUsername(data) {
  return request({
    url: BASE_PATH + "check-username",
    method: "POST",
    params: data,
  });
}

export function getCodeUrlUsing() {
  return BASE_PATH + "oauth2/wechat/code-img";
}

export function getAuthorizedStatusUsing() {
  return request({
    url: BASE_PATH + "oauth2/wechat/auth-refresh",
    method: "get",
  });
}

export function getProviders() {
  return request({
    url: BASE_PATH + "oauth2/providers",
    method: "get",
  });
}

export function getProfile() {
  return request({
    url: BASE_PATH + "profile",
    method: "get",
  });
}

// 工单
export function createIssue(data) {
  return request({
    url: "/front/issue/new",
    method: "POST",
    data,
  });
}
