<template>
  <div class="EduShopingCar">
    <el-tooltip content="购买课程" placement="bottom">
      <img
        class="EduMenuImg"
        style="cursor: pointer"
        src="@/assets/EduShopingCar.png"
        alt="EduShopingCar"
        @click="$router.push({ path: '/purchaseMore'})"
      />
    </el-tooltip>
  </div>
</template>

<script>
export default {
  name: "EduShopingCar",
};
</script>
